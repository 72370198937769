import React from 'react'
import { graphql, Link } from 'gatsby'
import Seo from '../components/SEO'
import email from '../images/icons/email.svg'
import linkedin from '../images/icons/linkedin.svg'
import twitter from '../images/icons/twitter.svg'

const BlogPage = ({ data }) => {
  const { post } = data
  if (!post)
    return (
      <div className="h-[80vh] flex items-center justify-center w-full">
        <div className="flex mx-auto items-center justify-center space-x-2 animate-pulse">
          <div className="w-8 h-8 bg-blue-400 rounded-full"></div>
          <div className="w-8 h-8 bg-blue-400 rounded-full"></div>
          <div className="w-8 h-8 bg-blue-400 rounded-full"></div>
          <div className="w-8 h-8 bg-blue-400 rounded-full"></div>
        </div>
      </div>
    )
  return (
    <>
      <Seo
        title={post?.title?.rendered}
        description={post?.excerpt?.rendered.slice(3, 100) + ' ...'}
        image={post.jetpack_featured_media_url}
      />
      <div className="flex flex-col lg:flex-row w-full ">
        <div className="w-full md:w-1/4 flex flex-col m-4 md:mt-20 md:pl-20">
          <Link
            to="/blog"
            className="hover:text-blue-600 cursor-pointer arrow no-underline"
          >
            All entries
          </Link>
          <div className="hidden lg:block mt-64">
            <span className="text-sm p-0">Follow us</span>
            <span className="uppercase text-base font-bold pt-0 block">
              Social Media
            </span>
            <div className="flex mt-2 items-center">
              <a target="_blank" href="https://linkedin.com/in/tillcarlos">
                <img
                  className="mr-3 mb-1"
                  src={linkedin}
                  alt="Linkedin"
                  srcset=""
                />
              </a>
              <a target="_blank" href="https://twitter.com/till_carlos">
                <img className="mx-3" src={twitter} alt="Twitter" srcset="" />
              </a>
              <a target="_blank" href="https://tillcarlos.com/updates/">
                <img className="ml-3" src={email} alt="Email" srcset="" />
              </a>
            </div>
          </div>
        </div>
        <div className="mx-auto md:mt-4  md:w-3/4 w-full">
          <div className="max-w-[850px] w-full flex flex-col  justify-center mt-4 md:mt-16 blog-container">
            <p className="font-extralight text-md text-gray-700">
              Published on {post?.date}
            </p>
            <h1 className="text-3xl md:text-5xl max-w-3xl font-black text-teal-900 mb-4  font-display">
              {post?.title?.rendered}
            </h1>

            {/* <div className="my-8">
              <img src={post.jetpack_featured_media_url} alt="featured image" />
            </div> */}
            {post && (
              <div
                className="max-w-[850px] text-start font-paragraph text-xl leading-[35px] px-0 "
                dangerouslySetInnerHTML={{ __html: post?.content?.rendered }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query ($slug: String!) {
    post: wPost(slug: { eq: $slug }) {
      title {
        rendered
      }
      date(formatString: "MMM DD YYYY")
      author
      content {
        rendered
      }
      excerpt {
        rendered
      }
      jetpack_featured_media_url
      slug
    }
  }
`
